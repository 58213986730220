<template>
  <div id="login" />
</template>

<script>
import { ModalList } from 'theme/store/ui/modals';
import { mapActions } from 'vuex';

export default {
  name: 'Login',
  mounted () {
    this.openModal({ name: ModalList.Auth, payload: 'auth' })
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal'
    })
  }
};
</script>
<style lang="scss" scoped>
#login {
  min-height: 624px;
  background-color: var(--green-light);
}
</style>
